import { CSSProperties, ReactNode } from 'react';
import { StepType } from '@reactour/tour';
import parse from 'html-react-parser';
import { px, vars } from '@lib/theme';
import { Flex, Heading, Text, TextProps } from '@lib/ui';

export type AugmentedStepType = StepType & StepContentType;

type OnAfterLoadCB = (
  steps: AugmentedStepType[],
  setSteps: (steps: AugmentedStepType[]) => void,
  currentPane: string
) => void;

export type StepContentType = {
  type: 'step' | 'takeover';
  title?: string;
  body?: string | ReactNode;
  videoSrc?: string;
  imageSrc?: string;
  delay?: number;
  // onBefore must be agnostic about its params. one tour may need args that differ from another. so far this is good enough.
  onBefore?: (args?: unknown) => void;
  onAfterLoad?: OnAfterLoadCB;
};

export const StepContentBody = ({ children, ...props }: TextProps) => {
  return (
    <Text {...props} size="16" lineHeight={vars.lineHeights.l4}>
      {children}
    </Text>
  );
};

export const StepContent = ({ title, body }: StepContentType) => {
  return (
    <Flex gap={vars.scales.s16} flexDirection="column">
      {title && (
        <Heading size="22" weight="bold">
          {title}
        </Heading>
      )}
      <StepContentBody>
        {typeof body === 'string' ? parse(body) : body}
      </StepContentBody>
    </Flex>
  );
};

const MediaStepContent = ({
  title,
  body,
  media,
}: Pick<StepContentType, 'title' | 'body'> & { media: ReactNode }) => {
  return (
    <div>
      {media}
      <Flex
        gap={vars.scales.s20}
        flexDirection="column"
        padding={vars.scales.s24}
      >
        {title && (
          <Heading size="28" weight="bold" align="center">
            {title}
          </Heading>
        )}
        <StepContentBody>
          {typeof body === 'string' ? parse(body) : body}
        </StepContentBody>
      </Flex>
    </div>
  );
};

const mediaStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: vars.zIndex.z100,
  width: px(480),
};

export const VideoStepContent = ({
  title,
  body,
  videoSrc,
}: StepContentType) => {
  let isVimeoLink = false;

  if (videoSrc) {
    try {
      isVimeoLink = new URL(videoSrc).hostname.includes('vimeo');
    } catch (error) {
      // noop
    }
  }

  if (isVimeoLink) {
    return (
      <MediaStepContent
        title={title}
        body={body}
        media={
          <iframe
            style={{ aspectRatio: '16/9', border: 'none', ...mediaStyles }}
            src={videoSrc}
            title="Video Guide"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        }
      />
    );
  }

  return (
    <MediaStepContent
      title={title}
      body={body}
      media={
        <video
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
          style={mediaStyles}
        />
      }
    />
  );
};

export const ImageStepContent = ({
  title,
  body,
  imageSrc,
}: StepContentType) => {
  return (
    <MediaStepContent
      title={title}
      body={body}
      media={<img src={imageSrc} style={mediaStyles} />}
    />
  );
};
