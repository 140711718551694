import { PROJECT_STATUS } from '@lib/types';

export const DEFAULT_PAGE_SIZE = '23';
export const DISPLAY_FORMAT = 'M/dd/yyyy';
export const DEFAULT_PROJECT_CONCEPT_MAX_SIZE = 750;
export const DEFAULT_PROJECT_TITLE_MAX_SIZE = 100;
export const DEFAULT_PROJECT_NAME_MAX_SIZE = 50;

export const ACCESS = {
  USER_OWNED: 'owner',
  SHARED: 'shared',
  CHANNEL: 'channel',
  ALL: 'all',
} as const;

export const LAYOUT = {
  GRID: 'grid',
  TABLE: 'table',
} as const;

export const STATUS = {
  ALL: 'all',
  ...PROJECT_STATUS,
} as const;

export const PUBLISHED = {
  TRUE: 'true',
  FALSE: 'false',
};

export const FILTER_CHIPS = {
  ACCESS: 'access',
  DATE_ADDED: 'date_added',
  PUBLISHED_DATE: 'published_date',
  SPONSOR: 'sponsor',
  THUMBNAIL: 'thumbnail',
  TITLE: 'title',
  CONCEPT: 'concept',
} as const;

export const SORT_ORDER = {
  EDITED_ASC: '-updatedAt',
  EDITED_DESC: 'updatedAt',
  PUBLISHED_ASC: '-publishDate',
  PUBLISHED_DESC: 'publishDate',
  CREATED_ASC: '-createdAt',
  CREATED_DESC: 'createdAt',
  VIEWED_ASC: 'viewed_asc',
  VIEWED_DESC: 'viewed_desc',
  NAME_ASC: 'name',
  NAME_DESC: '-name',
} as const;

export const PARAM_KEYS = {
  ACCESS: 'access',
  CONCENT_EXISTS: 'concept__exists',
  COUNT: 'count',
  CREATED_END: 'created_end',
  CREATED_START: 'created_start',
  LAYOUT: 'layout',
  PAGE: 'page',
  PUBLISH_DATE_BETWEEN: 'publishDate__between',
  PUBLISH_DATE_START: 'publishDate__gte',
  PUBLISH_DATE_END: 'publishDate__lte',
  PUBLISHED: 'published',
  RELEASED_END: 'released_end',
  RELEASED_START: 'released_start',
  SEARCH: 'search',
  SORT: 'sort',
  SORT_BY: 'sort_by',
  SPONSORS_EXISTS: 'sponsors__exists',
  STATUS_IN: 'status__in',
  STATUS_IS_NOT: 'status__isnot',
  THUMBNAIL_EXISTS: 'thumbnail__exists',
  TITLE_EXISTS: 'title__exists',
  TYPE: 'type',
  NEW_PROJECT_ID: 'newProjectId',
} as const;

export const DEFAULT_SORT_VALUES = {
  [PUBLISHED.FALSE]: SORT_ORDER.EDITED_ASC,
  [PUBLISHED.TRUE]: SORT_ORDER.PUBLISHED_ASC,
};

export const DEFAULT_VALUES = {
  [PARAM_KEYS.COUNT]: DEFAULT_PAGE_SIZE,
  [PARAM_KEYS.LAYOUT]: LAYOUT.GRID,
  [PARAM_KEYS.ACCESS]: ACCESS.ALL,
  [PARAM_KEYS.SORT_BY]: DEFAULT_SORT_VALUES[PUBLISHED.FALSE],
  [PARAM_KEYS.PUBLISHED]: PUBLISHED.FALSE,
  [PARAM_KEYS.STATUS_IN]: STATUS.ALL,
  [PARAM_KEYS.PUBLISH_DATE_START]: '',
  [PARAM_KEYS.PUBLISH_DATE_END]: '',
} as const;

export const DELETE_PROJECT_MODAL = 'delete-project-modal';
export const DELETE_THUMBNAIL_MODAL = 'delete-thumbnail-modal';

export const PANEL_CONTROL_BACKUPS_PREFIX = 'backups';
export const PANEL_CONTROL_BACKUPS_THUMB = 'backups-thumbnail';
export const PANEL_CONTROL_BACKUPS_TITLE = 'backups-title';
export const PANEL_CONTROL_BACKUPS_CONCEPT = 'backups-concept';
export const PANEL_CONTROL_INPUTS = 'ai';

export const PANELS = {
  PROPERTIES: 'properties',
  PREVIEW: 'preview',
  HISTORY: 'history',
  NOTEPAD: 'notepad',
  SETTINGS: 'settings',
};

export const ACCORDION_SECTION = {
  SELECTIONS: 'selections',
  INPUTS: 'inputs',
} as const;

export const STUDIO_SHOW_BRAINSTORM_POWER_KEYWORDS = 'studio_show_brainstorm_power_keywords';

export const PDP_NAV_LOCATIONS = {
  FOOTER: 'footer',
  RIGHT: 'right',
  TOP: 'top',
  MORE: 'more',
};

export const YT_PREVIEW_WIDTH_MINIMIZED_MOBILE = 320; // Todo fix popover mobile view
export const YT_PREVIEW_WIDTH_MAXIMIZED_MOBILE = 360;
export const YT_PREVIEW_MAXIMIZED_WIDTH = 1200;
export const YT_PREVIEW_MAXIMIZED_HEIGHT = 686;
export const YT_PREVIEW_MINIMIZED_WIDTH = 800;
