/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** BigInt scalar type */
  BigInt: { input: any; output: any; }
  /** Date custom scalar type */
  Date: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type Addons = {
  __typename?: 'Addons';
  _id: Scalars['ID']['output'];
  active: Scalars['Boolean']['output'];
  amount?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  discount?: Maybe<Discount>;
  name: Scalars['String']['output'];
  price: Price;
  sku: Scalars['String']['output'];
  tierMode?: Maybe<Scalars['String']['output']>;
  tiered: Scalars['Boolean']['output'];
  tiers?: Maybe<Tiers>;
  type: Scalars['String']['output'];
};

export type AutocompleteChannel = {
  __typename?: 'AutocompleteChannel';
  highlight?: Maybe<ChannelHighlight>;
  name: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  subscribers?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  ucid: Scalars['String']['output'];
};

export type AutocompleteChannelWithMeta = {
  __typename?: 'AutocompleteChannelWithMeta';
  edges: Array<AutocompleteChannel>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AutocompleteResults = {
  __typename?: 'AutocompleteResults';
  channelName?: Maybe<AutocompleteChannelWithMeta>;
  count?: Maybe<Scalars['Int']['output']>;
  videoTitle?: Maybe<AutocompleteVideoWithMeta>;
};

export type AutocompleteVideoWithMeta = {
  __typename?: 'AutocompleteVideoWithMeta';
  edges: Array<Video>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CancelSubscriptionInput = {
  invoiceNow?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type Channel = {
  __typename?: 'Channel';
  aiFeedBio?: Maybe<Scalars['String']['output']>;
  aiFeedVideoSummaries?: Maybe<Scalars['String']['output']>;
  aiFeedVideoTitles?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  channelBanner?: Maybe<Scalars['String']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  channelSource?: Maybe<Scalars['String']['output']>;
  channelThumbnail?: Maybe<Scalars['String']['output']>;
  connectChannelId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creatorDescription?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  highlight?: Maybe<ChannelHighlight>;
  id: Scalars['ID']['output'];
  isAuthorized?: Maybe<Scalars['Boolean']['output']>;
  kirbyChannelMetadata?: Maybe<KirbyChannelMetadata>;
  monetized: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform?: Maybe<Scalars['String']['output']>;
  platformAuthorizationDate?: Maybe<Scalars['String']['output']>;
  platformChannelDescription?: Maybe<Scalars['String']['output']>;
  platformChannelFamilySafe?: Maybe<Scalars['Boolean']['output']>;
  platformChannelVerified?: Maybe<Scalars['Boolean']['output']>;
  relatedChannels?: Maybe<Array<Channel>>;
  role?: Maybe<EntitlementType>;
  score?: Maybe<Scalars['Float']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<TeamsWithMeta>;
  ucid: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type ChannelKirbyChannelMetadataArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
};

export type ChannelAutocompleteInput = {
  itemsPerPage?: Scalars['Int']['input'];
  lookAheadTerm?: LookaheadTerm;
  page?: Scalars['Int']['input'];
  phraseSearch: Scalars['String']['input'];
  sortField?: InputMaybe<SortField>;
  sortOrder?: SortOrder;
};

export type ChannelBio = {
  __typename?: 'ChannelBio';
  results: Scalars['String']['output'];
};

export type ChannelBioInput = {
  includeTranscripts?: InputMaybe<Scalars['Boolean']['input']>;
  ucid: Scalars['String']['input'];
};

export type ChannelHighlight = {
  __typename?: 'ChannelHighlight';
  name: Array<Scalars['String']['output']>;
};

export enum ChannelSearchField {
  Id = 'id',
  Name = 'name',
  Ucid = 'ucid'
}

export type ChannelStats = {
  __typename?: 'ChannelStats';
  description?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  lifetimeViews?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  runDate?: Maybe<Scalars['String']['output']>;
  subscribers?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  ucid: Scalars['String']['output'];
  videoCount?: Maybe<Scalars['String']['output']>;
};

export type ChannelStatsListInput = {
  itemsPerPage?: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  sortField?: LabsChannelListSortField;
  sortOrder?: LabsChannelListSortOrder;
};

export type ChannelStatsListPagination = {
  __typename?: 'ChannelStatsListPagination';
  count?: Maybe<Scalars['Int']['output']>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  currentPageSize?: Maybe<Scalars['Int']['output']>;
  numPages?: Maybe<Scalars['Int']['output']>;
  results: Array<ChannelStats>;
};

export type ChannelsWithMeta = {
  __typename?: 'ChannelsWithMeta';
  edges: Array<Channel>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CheckoutInput = {
  organizationId: Scalars['String']['input'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  clientSecret?: Maybe<Scalars['String']['output']>;
  sessionId: Scalars['ID']['output'];
};

export type Collaborator = {
  __typename?: 'Collaborator';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
};

/** Mode of payment collection for the subscription */
export enum CollectionMode {
  Always = 'ALWAYS',
  IfRequired = 'IF_REQUIRED'
}

export type CreateChannelBioInput = {
  content: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type CreateChannelLogLineInput = {
  content: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type CreateChannelVideoTitleInput = {
  orgId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type CreateEntitlementInput = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  role: Scalars['String']['input'];
};

export type CreateFavoriteInput = {
  channelUcid: Scalars['ID']['input'];
  favoriteUcid: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateSubscriptionInput = {
  organizationId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
};

export type CreateUserInput = {
  entitlements?: InputMaybe<Array<EntitlementInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roles: Array<RoleType>;
  source?: InputMaybe<EntitlementSource>;
  userEmail: Scalars['String']['input'];
};

export type CreateVideoProjectInput = {
  channelId: Scalars['String']['input'];
  concept?: InputMaybe<TextInput>;
  meta?: InputMaybe<VideoProjectMetaInput>;
  orgId: Scalars['String']['input'];
  outline?: InputMaybe<Scalars['Boolean']['input']>;
  thumbnail?: InputMaybe<ThumbnailInput>;
  title?: InputMaybe<TextInput>;
};

export type DeleteChannelBioInput = {
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type DeleteChannelLogLineInput = {
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type DeleteChannelVideoTitleInput = {
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type DeleteFavoriteInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTeamUserInput = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type DeleteVideoProjectResponse = {
  __typename?: 'DeleteVideoProjectResponse';
  channelId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Discount = {
  __typename?: 'Discount';
  description?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
};

export type ElementOptionInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Entitlement = {
  __typename?: 'Entitlement';
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EntitlementInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum EntitlementSource {
  EarlyAccess = 'EARLY_ACCESS',
  Labs = 'LABS',
  Studio = 'STUDIO'
}

export enum EntitlementType {
  Manager = 'MANAGER',
  Owner = 'OWNER',
  ReadOnly = 'READ_ONLY',
  SpotterAdmin = 'SPOTTER_ADMIN'
}

export type FavoriteChannel = {
  __typename?: 'FavoriteChannel';
  createdAt?: Maybe<Scalars['String']['output']>;
  creatorChannelUcid?: Maybe<Scalars['String']['output']>;
  favoriteUcid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscribers?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  videoCount?: Maybe<Scalars['String']['output']>;
};

export type FavoriteChannelsResult = {
  __typename?: 'FavoriteChannelsResult';
  results: Array<FavoriteChannel>;
};

export type FreeTrial = {
  __typename?: 'FreeTrial';
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type GetVideoByIdInput = {
  id: Scalars['ID']['input'];
};

export type GetVideoInspirationInput = {
  ytVideo: Scalars['ID']['input'];
};

export type GetVideoProjectInput = {
  channelId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type GetVideoProjectsInput = {
  channelId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  entitlements?: InputMaybe<Array<CreateEntitlementInput>>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type KirbyCastMember = {
  __typename?: 'KirbyCastMember';
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  manual: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type KirbyChannelMetadata = {
  __typename?: 'KirbyChannelMetadata';
  bio?: Maybe<Scalars['String']['output']>;
  castMembers?: Maybe<Array<KirbyCastMember>>;
  creatorDescription?: Maybe<Scalars['String']['output']>;
  generatedLogLines?: Maybe<Array<Scalars['String']['output']>>;
  generatedVideoTitles?: Maybe<Array<VideoTitle>>;
  isKidFriendly: Scalars['Boolean']['output'];
  manualBios?: Maybe<Array<ManualBio>>;
  manualLogLines?: Maybe<Array<ManualLogline>>;
  manualVideoTitles?: Maybe<Array<ManualVideoTitle>>;
  socialLinks: Array<Scalars['String']['output']>;
  subscriberCount: Scalars['Int']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  videoCount: Scalars['Int']['output'];
  videos?: Maybe<Array<KirbyVideo>>;
};

export type KirbyVideo = {
  __typename?: 'KirbyVideo';
  caption?: Maybe<Scalars['String']['output']>;
  captionErrorMessage?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  youtubeId: Scalars['String']['output'];
};

export enum LabsChannelListSortField {
  LifetimeViews = 'LIFETIME_VIEWS',
  Subscribers = 'SUBSCRIBERS'
}

export enum LabsChannelListSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ListCategoriesInput = {
  includeCount?: InputMaybe<Scalars['Boolean']['input']>;
  includeVideos?: InputMaybe<Scalars['String']['input']>;
  parentCategories?: InputMaybe<Scalars['String']['input']>;
};

export type ListFavoritesInput = {
  channelUcid: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type ListVideosInput = {
  aawUcid?: InputMaybe<Scalars['String']['input']>;
  channelFavoritesUcid?: InputMaybe<Scalars['String']['input']>;
  durationGte?: InputMaybe<Scalars['String']['input']>;
  durationLte?: InputMaybe<Scalars['String']['input']>;
  engagementGte?: InputMaybe<Scalars['String']['input']>;
  engagementLte?: InputMaybe<Scalars['String']['input']>;
  globalSearch?: InputMaybe<Scalars['String']['input']>;
  globalSearchLogicalRelation?: InputMaybe<Scalars['String']['input']>;
  includeCount?: InputMaybe<Scalars['String']['input']>;
  itemsPerPage?: InputMaybe<Scalars['String']['input']>;
  madeForKids?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notApplyLangFilter?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  performanceIndexGte?: InputMaybe<Scalars['String']['input']>;
  performanceIndexLte?: InputMaybe<Scalars['String']['input']>;
  requesterUcid?: InputMaybe<Scalars['String']['input']>;
  searchBy?: InputMaybe<VideoSortBy>;
  searchMethod?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscribersGte?: InputMaybe<Scalars['String']['input']>;
  subscribersLte?: InputMaybe<Scalars['String']['input']>;
  ucid?: InputMaybe<Scalars['String']['input']>;
  uploadedEnd?: InputMaybe<Scalars['String']['input']>;
  uploadedStart?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  v7AvgGte?: InputMaybe<Scalars['String']['input']>;
  v7AvgLte?: InputMaybe<Scalars['String']['input']>;
  v7Gte?: InputMaybe<Scalars['String']['input']>;
  v7Lte?: InputMaybe<Scalars['String']['input']>;
  v30Gte?: InputMaybe<Scalars['String']['input']>;
  v30Lte?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
  viewsGte?: InputMaybe<Scalars['String']['input']>;
  viewsLte?: InputMaybe<Scalars['String']['input']>;
  youtubeCategory?: InputMaybe<Scalars['String']['input']>;
};

/** Lookahead term for search */
export enum LookaheadTerm {
  Channel = 'CHANNEL',
  Mixed = 'MIXED',
  Video = 'VIDEO'
}

export type ManualBio = {
  __typename?: 'ManualBio';
  channelId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ManualLogline = {
  __typename?: 'ManualLogline';
  channelId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ManualVideoTitle = {
  __typename?: 'ManualVideoTitle';
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addFavorite: FavoriteChannelsResult;
  cancelSubscription: Scalars['Boolean']['output'];
  createChannelBio: ManualBio;
  createChannelLogLine: ManualLogline;
  createChannelVideoTitle: ManualVideoTitle;
  createCheckoutSession: CheckoutSession;
  createSubscription: Scalars['Boolean']['output'];
  createUser: User;
  createVideoProject: VideoProjectWithRoom;
  deleteChannelBio: Scalars['Boolean']['output'];
  deleteChannelLogLine: Scalars['Boolean']['output'];
  deleteChannelVideoTitle: Scalars['Boolean']['output'];
  deleteFavorite?: Maybe<FavoriteChannel>;
  deleteTeam: Scalars['Boolean']['output'];
  deleteTeamUser: OrganizationUser;
  deleteUser: User;
  deleteVideoProject?: Maybe<DeleteVideoProjectResponse>;
  duplicateVideoProject?: Maybe<ProjectsResponseItem>;
  inviteUser: User;
  updateChannel: Channel;
  updateChannelBio: ManualBio;
  updateChannelCastMember: KirbyCastMember;
  updateChannelLogline: ManualLogline;
  updateChannelVideoTitle: ManualVideoTitle;
  updateEntitlement: Entitlement;
  updateTeam: Team;
  updateTeamUser: OrganizationUser;
  updateUser: User;
};


export type MutationAddFavoriteArgs = {
  input: CreateFavoriteInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCreateChannelBioArgs = {
  input: CreateChannelBioInput;
};


export type MutationCreateChannelLogLineArgs = {
  input: CreateChannelLogLineInput;
};


export type MutationCreateChannelVideoTitleArgs = {
  input: CreateChannelVideoTitleInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CheckoutInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVideoProjectArgs = {
  input: CreateVideoProjectInput;
};


export type MutationDeleteChannelBioArgs = {
  input: DeleteChannelBioInput;
};


export type MutationDeleteChannelLogLineArgs = {
  input: DeleteChannelLogLineInput;
};


export type MutationDeleteChannelVideoTitleArgs = {
  input: DeleteChannelVideoTitleInput;
};


export type MutationDeleteFavoriteArgs = {
  input: DeleteFavoriteInput;
};


export type MutationDeleteTeamArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteTeamUserArgs = {
  input: DeleteTeamUserInput;
};


export type MutationDeleteUserArgs = {
  input: UserInput;
};


export type MutationDeleteVideoProjectArgs = {
  input: VideoProjectInput;
};


export type MutationDuplicateVideoProjectArgs = {
  input: VideoProjectInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationUpdateChannelBioArgs = {
  input: UpdateChannelBioInput;
};


export type MutationUpdateChannelCastMemberArgs = {
  input: UpdateChannelCastMemberInput;
};


export type MutationUpdateChannelLoglineArgs = {
  input: UpdateChannelLoglineInput;
};


export type MutationUpdateChannelVideoTitleArgs = {
  input: UpdateChannelVideoTitleInput;
};


export type MutationUpdateEntitlementArgs = {
  input: EntitlementInput;
};


export type MutationUpdateTeamArgs = {
  input: TeamInput;
};


export type MutationUpdateTeamUserArgs = {
  input: UpdateTeamUserInput;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};

export type OrganizationInput = {
  organizationId: Scalars['String']['input'];
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organization?: Maybe<Team>;
  organizationId: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type OrganizationUserInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type Plan = {
  __typename?: 'Plan';
  _id: Scalars['ID']['output'];
  active: Scalars['Boolean']['output'];
  allowCoupon: Scalars['Boolean']['output'];
  collectionMode?: Maybe<CollectionMode>;
  cost: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  maxChannelCount?: Maybe<Scalars['Float']['output']>;
  maxSeatCount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  price: Price;
  sku: Scalars['String']['output'];
};

export type PlanInput = {
  _id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type PlansWithMeta = {
  __typename?: 'PlansWithMeta';
  edges: Array<Plan>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Price = {
  __typename?: 'Price';
  annual: Scalars['Float']['output'];
  annualStripeId: Scalars['String']['output'];
  monthly: Scalars['Float']['output'];
  monthlyStripeId: Scalars['String']['output'];
};

export type ProjectPage = {
  __typename?: 'ProjectPage';
  current: Scalars['Float']['output'];
  next?: Maybe<Scalars['Float']['output']>;
  previous?: Maybe<Scalars['Float']['output']>;
};

/** The various stages a project can be in */
export enum ProjectStatusType {
  Idea = 'IDEA',
  InDevelopment = 'IN_DEVELOPMENT',
  InPostProduction = 'IN_POST_PRODUCTION',
  Published = 'PUBLISHED',
  ReadyToPublish = 'READY_TO_PUBLISH'
}

export type ProjectsResponseItem = {
  __typename?: 'ProjectsResponseItem';
  channelUCID: Scalars['String']['output'];
  collaborators: Array<Collaborator>;
  concept?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  outline?: Maybe<Scalars['Boolean']['output']>;
  publishDate?: Maybe<Scalars['String']['output']>;
  sponsors: Array<Scalars['String']['output']>;
  status: ProjectStatusType;
  tags: Array<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  videoProjectId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  categories: VideoCategoriesWithMeta;
  channel: Channel;
  channelAutocomplete: AutocompleteResults;
  channelStatsList: ChannelStatsListPagination;
  channels: ChannelsWithMeta;
  favoriteChannels: FavoriteChannelsResult;
  generateBio: ChannelBio;
  getVideoById: Video;
  getVideoInspiration: Array<VideoInspiration>;
  getVideoProject: ProjectsResponseItem;
  getVideoProjects: VideoProject;
  plans: PlansWithMeta;
  recentVideoTitles: RecentVideoTitlesWithMeta;
  team: Team;
  teams: TeamsWithMeta;
  user: User;
  users: UsersWithMeta;
  videoEmpiricalSearch: Array<Video>;
  videos: VideoDataPagination;
  youtubeCategories: YoutubeCategoriesWithMeta;
};


export type QueryCategoriesArgs = {
  input: ListCategoriesInput;
};


export type QueryChannelArgs = {
  channelId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChannelAutocompleteArgs = {
  input: ChannelAutocompleteInput;
};


export type QueryChannelStatsListArgs = {
  input: ChannelStatsListInput;
};


export type QueryChannelsArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchBy?: InputMaybe<ChannelSearchField>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFavoriteChannelsArgs = {
  input: ListFavoritesInput;
};


export type QueryGenerateBioArgs = {
  input: ChannelBioInput;
};


export type QueryGetVideoByIdArgs = {
  input: GetVideoByIdInput;
};


export type QueryGetVideoInspirationArgs = {
  input: GetVideoInspirationInput;
};


export type QueryGetVideoProjectArgs = {
  input: GetVideoProjectInput;
};


export type QueryGetVideoProjectsArgs = {
  input: GetVideoProjectsInput;
};


export type QueryPlansArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRecentVideoTitlesArgs = {
  input: RecentVideoTitlesInput;
};


export type QueryTeamArgs = {
  input: OrganizationInput;
};


export type QueryTeamsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  input: UsersInput;
};


export type QueryVideoEmpiricalSearchArgs = {
  input: SearchEmpiricalTitleInput;
};


export type QueryVideosArgs = {
  input: ListVideosInput;
};


export type QueryYoutubeCategoriesArgs = {
  input: YoutubeCategoriesInput;
};

export type RecentVideoTitlesInput = {
  includeVideoId?: InputMaybe<Scalars['Boolean']['input']>;
  sortingOption?: InputMaybe<SortingOption>;
  stripHashtags?: InputMaybe<Scalars['Boolean']['input']>;
  ucid: Scalars['String']['input'];
};

export type RecentVideoTitlesWithMeta = {
  __typename?: 'RecentVideoTitlesWithMeta';
  edges: Array<VideoTitle>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  prevPage?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export enum RoleType {
  Admin = 'ADMIN',
  ArgocdDevops = 'ARGOCD_DEVOPS',
  EarlyAccess = 'EARLY_ACCESS',
  EarlyAccessLegacy = 'EARLY_ACCESS_LEGACY',
  External = 'External',
  Internal = 'INTERNAL',
  Labs = 'LABS',
  SpotterAdmin = 'SPOTTER_ADMIN',
  SpotterAdminLegacy = 'SPOTTER_ADMIN_LEGACY',
  Studio = 'STUDIO'
}

export type SearchEmpiricalTitleInput = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perfIndexWeightItem1?: InputMaybe<Scalars['Float']['input']>;
  perfIndexWeightItem2?: InputMaybe<Scalars['Float']['input']>;
  perfIndexWeightItem3?: InputMaybe<Scalars['Float']['input']>;
  subscribersWeightItem1?: InputMaybe<Scalars['Float']['input']>;
  subscribersWeightItem2?: InputMaybe<Scalars['Float']['input']>;
  subscribersWeightItem3?: InputMaybe<Scalars['Float']['input']>;
  titleSearch: Scalars['String']['input'];
  uploadedWeightItem1?: InputMaybe<Scalars['Float']['input']>;
  uploadedWeightItem2?: InputMaybe<Scalars['Float']['input']>;
  uploadedWeightItem3?: InputMaybe<Scalars['Float']['input']>;
  viewsWeightItem1?: InputMaybe<Scalars['Float']['input']>;
  viewsWeightItem2?: InputMaybe<Scalars['Float']['input']>;
  viewsWeightItem3?: InputMaybe<Scalars['Float']['input']>;
};

/** Field to use when sorting the results */
export enum SortField {
  Subscribers = 'SUBSCRIBERS'
}

/** Order to use when sorting the results */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Options for sorting the results */
export enum SortingOption {
  CustomSorting = 'CUSTOM_SORTING',
  RecentUploadRank = 'RECENT_UPLOAD_RANK'
}

export type Subscription = {
  __typename?: 'Subscription';
  addonsItems?: Maybe<Array<Addons>>;
  chargeAmount: Scalars['Float']['output'];
  currentChannels: Array<Scalars['String']['output']>;
  currentSeats: Array<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  failedBillingAttempts: Scalars['Float']['output'];
  freeTrial?: Maybe<FreeTrial>;
  id: Scalars['ID']['output'];
  interval?: Maybe<Scalars['String']['output']>;
  lastBillDate?: Maybe<Scalars['String']['output']>;
  lastChargedAmount: Scalars['Float']['output'];
  orgId: Scalars['String']['output'];
  plan: Plan;
  reason?: Maybe<Scalars['String']['output']>;
  renewalDate?: Maybe<Scalars['String']['output']>;
  resumesAt?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
};

export type SubscriptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  plan?: InputMaybe<PlanInput>;
  planId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionsWithMeta = {
  __typename?: 'SubscriptionsWithMeta';
  edges: Array<Subscription>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Team = {
  __typename?: 'Team';
  assignedChannels: Scalars['Int']['output'];
  assignedSeats: Scalars['Int']['output'];
  billingRequired: Scalars['Boolean']['output'];
  channels: ChannelsWithMeta;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSpotterOrganization?: Maybe<Scalars['Boolean']['output']>;
  maxChannels: Scalars['Int']['output'];
  maxSeats: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizationUsers: Array<OrganizationUser>;
  subscriptionActive: Scalars['Boolean']['output'];
  subscriptionType?: Maybe<Scalars['String']['output']>;
  subscriptions: SubscriptionsWithMeta;
  users: UsersWithMeta;
};

export type TeamInput = {
  id: Scalars['String']['input'];
  owner?: InputMaybe<OrganizationUserInput>;
  subscriptions?: InputMaybe<Array<SubscriptionInput>>;
};

export type TeamsWithMeta = {
  __typename?: 'TeamsWithMeta';
  edges: Array<Team>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TextInput = {
  options?: InputMaybe<Array<ElementOptionInput>>;
  primary?: InputMaybe<Scalars['String']['input']>;
};

export type ThumbnailInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<ElementOptionInput>>;
  primary?: InputMaybe<Scalars['String']['input']>;
};

export type Tier = {
  __typename?: 'Tier';
  flatAmount?: Maybe<Scalars['Float']['output']>;
  flatDecimalAmount?: Maybe<Scalars['String']['output']>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
  unitDecimalAmount?: Maybe<Scalars['String']['output']>;
  upTo?: Maybe<Scalars['Int']['output']>;
};

export type Tiers = {
  __typename?: 'Tiers';
  annual?: Maybe<Array<Tier>>;
  monthly?: Maybe<Array<Tier>>;
};

export type UpdateChannelBioInput = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type UpdateChannelCastMemberInput = {
  description: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type UpdateChannelInput = {
  aiFeedBio?: InputMaybe<Scalars['String']['input']>;
  aiFeedVideoSummaries?: InputMaybe<Array<Scalars['String']['input']>>;
  aiFeedVideoTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  bio?: InputMaybe<Scalars['String']['input']>;
  creatorDescription?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateChannelLoglineInput = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type UpdateChannelVideoTitleInput = {
  id: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  ucid: Scalars['String']['input'];
};

export type UpdateOrganizationUserEntitlementInput = {
  channelId: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type UpdateTeamUser = {
  email: Scalars['String']['input'];
};

export type UpdateTeamUserInput = {
  entitlements?: InputMaybe<Array<UpdateOrganizationUserEntitlementInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UpdateTeamUser>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  entitlements?: Maybe<Array<Entitlement>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  organization: Team;
  organizationUsers?: Maybe<Array<OrganizationUser>>;
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  entitlements?: InputMaybe<Array<EntitlementInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationUsers?: InputMaybe<Array<OrganizationUserInput>>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<RoleType>>;
};

export type UsersInput = {
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type UsersWithMeta = {
  __typename?: 'UsersWithMeta';
  edges: Array<User>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Video = {
  __typename?: 'Video';
  category?: Maybe<Scalars['String']['output']>;
  channelThumbnail: Scalars['String']['output'];
  clusterIndex?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  highlight?: Maybe<VideoHighlight>;
  id: Scalars['ID']['output'];
  madeForKids?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  performanceIndex?: Maybe<Scalars['Float']['output']>;
  predictedCategoryLevels1?: Maybe<Scalars['String']['output']>;
  predictedCategoryLevels2?: Maybe<Scalars['String']['output']>;
  predictedCategoryLevels3?: Maybe<Scalars['String']['output']>;
  predictedCategoryTags1?: Maybe<Scalars['String']['output']>;
  predictedCategoryTags2?: Maybe<Scalars['String']['output']>;
  predictedCategoryTags3?: Maybe<Scalars['String']['output']>;
  subscribers?: Maybe<Scalars['Int']['output']>;
  thumb: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  title1: Scalars['String']['output'];
  titleLanguage?: Maybe<Scalars['String']['output']>;
  ucid: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  uploaded?: Maybe<Scalars['String']['output']>;
  v7Average?: Maybe<Scalars['Float']['output']>;
  v7Score?: Maybe<Scalars['Int']['output']>;
  v30Score?: Maybe<Scalars['Int']['output']>;
  videoId: Scalars['String']['output'];
  views?: Maybe<Scalars['BigInt']['output']>;
  youtubeCategory: Scalars['String']['output'];
};

export type VideoCategoriesWithMeta = {
  __typename?: 'VideoCategoriesWithMeta';
  edges: Array<VideoCategory>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type VideoCategory = {
  __typename?: 'VideoCategory';
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type VideoDataPagination = {
  __typename?: 'VideoDataPagination';
  count?: Maybe<Scalars['Int']['output']>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  currentPageSize?: Maybe<Scalars['Int']['output']>;
  numPages?: Maybe<Scalars['Int']['output']>;
  results: Array<Video>;
};

export type VideoHighlight = {
  __typename?: 'VideoHighlight';
  title1: Array<Scalars['String']['output']>;
};

export type VideoInspiration = {
  __typename?: 'VideoInspiration';
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoId: Scalars['String']['output'];
};

export type VideoProject = {
  __typename?: 'VideoProject';
  page: ProjectPage;
  results: Array<ProjectsResponseItem>;
  totalRecords: Scalars['Int']['output'];
};

export type VideoProjectInput = {
  channelId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  orgId: Scalars['String']['input'];
};

export type VideoProjectMetaInput = {
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishDate?: InputMaybe<Scalars['DateTime']['input']>;
  sponsors?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<VideoProjectStatus>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  duration?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VideoProjectType>;
};

export enum VideoProjectStatus {
  Idea = 'idea',
  InDevelopment = 'in_development',
  InPostProduction = 'in_post_production',
  Published = 'published',
  ReadyToPublish = 'ready_to_publish'
}

export enum VideoProjectType {
  Adventure = 'adventure',
  AdviceAndInspiration = 'advice_and_inspiration',
  BehindTheScenes = 'behind_the_scenes',
  Challenge = 'challenge',
  ComedySketch = 'comedy_sketch',
  Education = 'education',
  Exploration = 'exploration',
  FamilyAdventure = 'family_adventure',
  Gaming = 'gaming',
  General = 'general',
  Listicle = 'listicle',
  Prank = 'prank',
  Reaction = 'reaction',
  Review = 'review',
  SatireAndParody = 'satire_and_parody',
  SportsRelated = 'sports_related',
  Stunt = 'stunt',
  Tutorial = 'tutorial',
  Vlog = 'vlog'
}

export type VideoProjectWithRoom = {
  __typename?: 'VideoProjectWithRoom';
  createdAt: Scalars['String']['output'];
  defaultAccesses: Array<Scalars['String']['output']>;
  groupsAccesses: Array<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  lastConnectionAt?: Maybe<Scalars['String']['output']>;
  metadata: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  usersAccesses: Array<Array<Scalars['String']['output']>>;
  videoProjectId: Scalars['String']['output'];
};

/** Sort by video titles or channels */
export enum VideoSortBy {
  All = 'all',
  Channels = 'channels',
  Titles = 'titles'
}

export type VideoTitle = {
  __typename?: 'VideoTitle';
  category?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  youTubeCategory?: Maybe<Scalars['String']['output']>;
};

export type YoutubeCategoriesInput = {
  items_per_page?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  ucid: Scalars['String']['input'];
};

export type YoutubeCategoriesWithMeta = {
  __typename?: 'YoutubeCategoriesWithMeta';
  edges: Array<Scalars['String']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ChannelItemFragment =
  {
    __typename?: 'Channel',
    id: string,
    name: string,
    email?: string | null,
    ucid: string,
    category?: string | null,
    createdAt?: any | null,
    updatedAt?: any | null,
    monetized: boolean,
    channelBanner?: string | null,
    channelThumbnail?: string | null,
    connectChannelId: string,
    teams?: {
      __typename?: 'TeamsWithMeta',
      edges: Array<{ __typename?: 'Team', id: string, name?: string | null, isSpotterOrganization?: boolean | null }>
    } | null
  }
  & { ' $fragmentName'?: 'ChannelItemFragment' };

export type ChannelQueryVariables = Exact<{
  channelId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChannelQuery = {
  __typename?: 'Query', channel: (
    {
      __typename?: 'Channel', kirbyChannelMetadata?: {
        __typename?: 'KirbyChannelMetadata',
        bio?: string | null,
        videoCount: number,
        tags?: Array<string> | null,
        subscriberCount: number,
        socialLinks: Array<string>,
        isKidFriendly: boolean,
        generatedLogLines?: Array<string> | null,
        creatorDescription?: string | null,
        videos?: Array<{ __typename?: 'KirbyVideo', id: string, title: string, caption?: string | null }> | null,
        manualLogLines?: Array<{
          __typename?: 'ManualLogline',
          id: string,
          content: string,
          channelId: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string
        }> | null,
        generatedVideoTitles?: Array<{
          __typename?: 'VideoTitle',
          title?: string | null,
          thumbnail?: string | null
        }> | null,
        manualVideoTitles?: Array<{
          __typename?: 'ManualVideoTitle',
          id: string,
          title: string,
          channelId: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string
        }> | null,
        manualBios?: Array<{
          __typename?: 'ManualBio',
          id: string,
          content: string,
          channelId: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string
        }> | null,
        castMembers?: Array<{
          __typename?: 'KirbyCastMember',
          id: string,
          name: string,
          description?: string | null,
          manual: boolean,
          createdAt: string,
          updatedAt: string,
          imageUrl?: string | null
        }> | null
      } | null
    }
    & { ' $fragmentRefs'?: { 'ChannelItemFragment': ChannelItemFragment } }
    )
};

export type ChannelsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  searchBy?: InputMaybe<ChannelSearchField>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChannelsQuery = {
  __typename?: 'Query', channels: {
    __typename?: 'ChannelsWithMeta',
    page?: number | null,
    nextPage?: number | null,
    prevPage?: number | null,
    pageSize?: number | null,
    total?: number | null,
    edges: Array<(
      { __typename?: 'Channel' }
      & { ' $fragmentRefs'?: { 'ChannelItemFragment': ChannelItemFragment } }
      )>
  }
};

export type CreateVideoTitleMutationVariables = Exact<{
  input: CreateChannelVideoTitleInput;
}>;


export type CreateVideoTitleMutation = {
  __typename?: 'Mutation',
  createChannelVideoTitle: {
    __typename?: 'ManualVideoTitle',
    id: string,
    title: string,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    organizationId: string
  }
};

export type UpdateChannelVideoTitleMutationVariables = Exact<{
  input: UpdateChannelVideoTitleInput;
}>;


export type UpdateChannelVideoTitleMutation = {
  __typename?: 'Mutation',
  updateChannelVideoTitle: {
    __typename?: 'ManualVideoTitle',
    id: string,
    title: string,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    organizationId: string
  }
};

export type DeleteChannelVideoTitleMutationVariables = Exact<{
  input: DeleteChannelVideoTitleInput;
}>;


export type DeleteChannelVideoTitleMutation = { __typename?: 'Mutation', deleteChannelVideoTitle: boolean };

export type CreateChannelBioMutationVariables = Exact<{
  input: CreateChannelBioInput;
}>;


export type CreateChannelBioMutation = {
  __typename?: 'Mutation',
  createChannelBio: {
    __typename?: 'ManualBio',
    id: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    organizationId: string
  }
};

export type CreateChannelLogLineMutationVariables = Exact<{
  input: CreateChannelLogLineInput;
}>;


export type CreateChannelLogLineMutation = {
  __typename?: 'Mutation',
  createChannelLogLine: {
    __typename?: 'ManualLogline',
    id: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    organizationId: string
  }
};

export type UpdateChannelLoglineMutationVariables = Exact<{
  input: UpdateChannelLoglineInput;
}>;


export type UpdateChannelLoglineMutation = {
  __typename?: 'Mutation',
  updateChannelLogline: {
    __typename?: 'ManualLogline',
    id: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    organizationId: string
  }
};

export type DeleteChannelLogLineMutationVariables = Exact<{
  input: DeleteChannelLogLineInput;
}>;


export type DeleteChannelLogLineMutation = { __typename?: 'Mutation', deleteChannelLogLine: boolean };

export type UpdateChannelCastMemberMutationVariables = Exact<{
  input: UpdateChannelCastMemberInput;
}>;


export type UpdateChannelCastMemberMutation = {
  __typename?: 'Mutation',
  updateChannelCastMember: {
    __typename?: 'KirbyCastMember',
    id: string,
    name: string,
    manual: boolean,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    description?: string | null
  }
};

export type UpdateChannelBioMutationVariables = Exact<{
  input: UpdateChannelBioInput;
}>;


export type UpdateChannelBioMutation = {
  __typename?: 'Mutation',
  updateChannelBio: {
    __typename?: 'ManualBio',
    id: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    channelId: string,
    organizationId: string
  }
};

export type DeleteChannelBioMutationVariables = Exact<{
  input: DeleteChannelBioInput;
}>;


export type DeleteChannelBioMutation = { __typename?: 'Mutation', deleteChannelBio: boolean };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: boolean };

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput;
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: boolean };

export type TeamItemFragment = {
  __typename?: 'Team',
  id: string,
  name?: string | null,
  channels: {
    __typename?: 'ChannelsWithMeta',
    edges: Array<{ __typename?: 'Channel', id: string, name: string, slug?: string | null }>
  },
  subscriptions: {
    __typename?: 'SubscriptionsWithMeta',
    edges: Array<{
      __typename?: 'Subscription',
      id: string,
      status: string,
      interval?: string | null,
      startDate?: string | null,
      resumesAt?: any | null,
      lastBillDate?: string | null,
      chargeAmount: number,
      stripeSubscriptionId: string,
      failedBillingAttempts: number,
      lastChargedAmount: number,
      freeTrial?: { __typename?: 'FreeTrial', startDate?: string | null, endDate?: string | null } | null,
      plan: {
        __typename?: 'Plan',
        _id: string,
        name: string,
        cost: number,
        description: string,
        price: {
          __typename?: 'Price',
          annual: number,
          monthly: number,
          annualStripeId: string,
          monthlyStripeId: string
        }
      }
    }>
  },
  organizationUsers: Array<{
    __typename?: 'OrganizationUser',
    id: string,
    role?: string | null,
    organizationId: string,
    user?: {
      __typename?: 'User',
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      entitlements?: Array<{
        __typename?: 'Entitlement',
        channel?: { __typename?: 'Channel', id: string, slug?: string | null, name: string, ucid: string } | null
      }> | null
    } | null
  }>
} & { ' $fragmentName'?: 'TeamItemFragment' };

export type GetTeamQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type GetTeamQuery = {
  __typename?: 'Query', team: (
    { __typename?: 'Team' }
    & { ' $fragmentRefs'?: { 'TeamItemFragment': TeamItemFragment } }
    )
};

export type GetTeamsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTeamsQuery = {
  __typename?: 'Query',
  teams: {
    __typename?: 'TeamsWithMeta',
    edges: Array<{
      __typename?: 'Team',
      id: string,
      name?: string | null,
      channels: {
        __typename?: 'ChannelsWithMeta',
        edges: Array<{ __typename?: 'Channel', id: string, name: string, slug?: string | null }>
      }
    }>
  }
};

export type GetUserTeamsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserTeamsQuery = {
  __typename?: 'Query', teams: {
    __typename?: 'TeamsWithMeta', edges: Array<{
      __typename?: 'Team',
      id: string,
      name?: string | null,
      maxChannels: number,
      maxSeats: number,
      assignedSeats: number,
      assignedChannels: number,
      createdAt: string,
      channels: {
        __typename?: 'ChannelsWithMeta',
        edges: Array<{
          __typename?: 'Channel',
          id: string,
          ucid: string,
          name: string,
          channelThumbnail?: string | null
        }>
      },
      organizationUsers: Array<{
        __typename?: 'OrganizationUser',
        id: string,
        role?: string | null,
        status?: string | null,
        organizationId: string,
        user?: {
          __typename?: 'User',
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          permissions?: Array<string> | null,
          profilePicture?: string | null,
          jobTitle?: string | null,
          phoneNumber?: string | null,
          entitlements?: Array<{
            __typename?: 'Entitlement',
            id: string,
            role?: string | null,
            source?: string | null,
            userId?: string | null,
            channelId?: string | null,
            organizationId?: string | null,
            channel?: { __typename?: 'Channel', id: string, name: string, channelThumbnail?: string | null } | null
          }> | null
        } | null
      }>,
      subscriptions: {
        __typename?: 'SubscriptionsWithMeta',
        edges: Array<{
          __typename?: 'Subscription',
          id: string,
          status: string,
          interval?: string | null,
          startDate?: string | null,
          resumesAt?: any | null,
          lastBillDate?: string | null,
          chargeAmount: number,
          stripeSubscriptionId: string,
          failedBillingAttempts: number,
          lastChargedAmount: number,
          freeTrial?: { __typename?: 'FreeTrial', startDate?: string | null, endDate?: string | null } | null,
          plan: {
            __typename?: 'Plan',
            _id: string,
            name: string,
            cost: number,
            description: string,
            price: { __typename?: 'Price', annual: number, monthly: number }
          }
        }>
      }
    }>
  }
};

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = {
  __typename?: 'Query',
  plans: { __typename?: 'PlansWithMeta', edges: Array<{ __typename?: 'Plan', _id: string, name: string, sku: string }> }
};

export type DeleteTeamUserMutationVariables = Exact<{
  input: DeleteTeamUserInput;
}>;


export type DeleteTeamUserMutation = {
  __typename?: 'Mutation',
  deleteTeamUser: { __typename?: 'OrganizationUser', id: string }
};

export type DeleteTeamMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type DeleteTeamMutation = { __typename?: 'Mutation', deleteTeam: boolean };

export type UpdateTeamUserMutationVariables = Exact<{
  input: UpdateTeamUserInput;
}>;


export type UpdateTeamUserMutation = {
  __typename?: 'Mutation',
  updateTeamUser: { __typename?: 'OrganizationUser', id: string }
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;


export type InviteUserMutation = {
  __typename?: 'Mutation',
  inviteUser: {
    __typename?: 'User',
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    jobTitle?: string | null,
    phoneNumber?: string | null
  }
};

export type UpdateEntitlementMutationVariables = Exact<{
  input: EntitlementInput;
}>;


export type UpdateEntitlementMutation = {
  __typename?: 'Mutation',
  updateEntitlement: { __typename?: 'Entitlement', id: string, role?: string | null }
};

export type UpdateTeamMutationVariables = Exact<{
  input: TeamInput;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', id: string } };

export type UserItemFragment =
  {
    __typename?: 'User',
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    jobTitle?: string | null,
    phoneNumber?: string | null,
    profilePicture?: string | null,
    createdAt?: any | null,
    updatedAt?: any | null,
    permissions?: Array<string> | null,
    organizationUsers?: Array<{
      __typename?: 'OrganizationUser',
      id: string,
      role?: string | null,
      userId: string,
      organizationId: string,
      status?: string | null,
      organization?: { __typename?: 'Team', id: string, name?: string | null } | null
    }> | null,
    entitlements?: Array<{
      __typename?: 'Entitlement',
      id: string,
      organizationId?: string | null,
      userId?: string | null,
      source?: string | null,
      updatedAt?: any | null,
      createdAt?: any | null,
      role?: string | null,
      channel?: {
        __typename?: 'Channel',
        id: string,
        slug?: string | null,
        name: string,
        ucid: string,
        channelBanner?: string | null,
        channelThumbnail?: string | null
      } | null
    }> | null
  }
  & { ' $fragmentName'?: 'UserItemFragment' };

export type GetUserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserQuery = {
  __typename?: 'Query', user: (
    { __typename?: 'User' }
    & { ' $fragmentRefs'?: { 'UserItemFragment': UserItemFragment } }
    )
};

export type GetUsersQueryVariables = Exact<{
  input: UsersInput;
}>;


export type GetUsersQuery = {
  __typename?: 'Query', users: {
    __typename?: 'UsersWithMeta', page: number, pageSize: number, total: number, edges: Array<(
      { __typename?: 'User' }
      & { ' $fragmentRefs'?: { 'UserItemFragment': UserItemFragment } }
      )>
  }
};

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = {
  __typename?: 'Mutation',
  updateUser: {
    __typename?: 'User',
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    jobTitle?: string | null,
    phoneNumber?: string | null
  }
};

export type UpdateOrgMutationVariables = Exact<{
  input: UserInput;
  teamInput: TeamInput;
}>;


export type UpdateOrgMutation = {
  __typename?: 'Mutation',
  updateUser: {
    __typename?: 'User',
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    jobTitle?: string | null,
    phoneNumber?: string | null
  },
  updateTeam: { __typename?: 'Team', id: string }
};

export type DeleteUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type GetVideoInspirationQueryVariables = Exact<{
  input: GetVideoInspirationInput;
}>;


export type GetVideoInspirationQuery = {
  __typename?: 'Query',
  getVideoInspiration: Array<{
    __typename?: 'VideoInspiration',
    thumbnail?: string | null,
    title?: string | null,
    videoId: string
  }>
};

export type ChannelAutocompleteQueryVariables = Exact<{
  input: ChannelAutocompleteInput;
}>;


export type ChannelAutocompleteQuery = {
  __typename?: 'Query',
  channelAutocomplete: {
    __typename?: 'AutocompleteResults',
    count?: number | null,
    videoTitle?: {
      __typename?: 'AutocompleteVideoWithMeta',
      page?: number | null,
      total?: number | null,
      prevPage?: number | null,
      nextPage?: number | null,
      pageSize?: number | null,
      edges: Array<{
        __typename?: 'Video',
        title?: string | null,
        videoId: string,
        highlight?: { __typename?: 'VideoHighlight', title1: Array<string> } | null
      }>
    } | null,
    channelName?: {
      __typename?: 'AutocompleteChannelWithMeta',
      prevPage?: number | null,
      nextPage?: number | null,
      total?: number | null,
      page?: number | null,
      pageSize?: number | null,
      edges: Array<{
        __typename?: 'AutocompleteChannel',
        name: string,
        ucid: string,
        subscribers?: number | null,
        thumbnail?: string | null,
        score?: number | null,
        highlight?: { __typename?: 'ChannelHighlight', name: Array<string> } | null
      }>
    } | null
  }
};

export type AddFavoriteMutationVariables = Exact<{
  input: CreateFavoriteInput;
}>;


export type AddFavoriteMutation = {
  __typename?: 'Mutation',
  addFavorite: {
    __typename?: 'FavoriteChannelsResult',
    results: Array<{
      __typename?: 'FavoriteChannel',
      id: string,
      creatorChannelUcid?: string | null,
      favoriteUcid?: string | null,
      organizationId?: string | null,
      name?: string | null,
      thumbnail?: string | null,
      subscribers?: string | null,
      videoCount?: string | null,
      keywords?: string | null
    }>
  }
};

export type DeleteFavoriteMutationVariables = Exact<{
  input: DeleteFavoriteInput;
}>;


export type DeleteFavoriteMutation = {
  __typename?: 'Mutation',
  deleteFavorite?: { __typename?: 'FavoriteChannel', status?: string | null } | null
};

export type FavoriteChannelsQueryVariables = Exact<{
  input: ListFavoritesInput;
}>;


export type FavoriteChannelsQuery = {
  __typename?: 'Query',
  favoriteChannels: {
    __typename?: 'FavoriteChannelsResult',
    results: Array<{
      __typename?: 'FavoriteChannel',
      id: string,
      creatorChannelUcid?: string | null,
      organizationId?: string | null,
      favoriteUcid?: string | null,
      name?: string | null,
      thumbnail?: string | null,
      subscribers?: string | null,
      status?: string | null,
      videoCount?: string | null,
      keywords?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null
    }>
  }
};

export type GetVideosQueryVariables = Exact<{
  input: ListVideosInput;
}>;


export type GetVideosQuery = {
  __typename?: 'Query',
  videos: {
    __typename?: 'VideoDataPagination',
    count?: number | null,
    currentPage?: number | null,
    currentPageSize?: number | null,
    numPages?: number | null,
    results: Array<{
      __typename?: 'Video',
      category?: string | null,
      channelThumbnail: string,
      clusterIndex?: string | null,
      createdAt?: string | null,
      duration?: number | null,
      email?: string | null,
      engagement?: number | null,
      id: string,
      madeForKids?: boolean | null,
      name: string,
      performanceIndex?: number | null,
      predictedCategoryLevels1?: string | null,
      predictedCategoryLevels2?: string | null,
      predictedCategoryLevels3?: string | null,
      predictedCategoryTags1?: string | null,
      predictedCategoryTags2?: string | null,
      predictedCategoryTags3?: string | null,
      subscribers?: number | null,
      thumb: string,
      title1: string,
      titleLanguage?: string | null,
      updatedAt: string,
      uploaded?: string | null,
      ucid: string,
      v30Score?: number | null,
      v7Average?: number | null,
      v7Score?: number | null,
      videoId: string,
      views?: any | null,
      youtubeCategory: string
    }>
  }
};

export type GetVideoProjectQueryVariables = Exact<{
  input: GetVideoProjectInput;
}>;


export type GetVideoProjectQuery = {
  __typename?: 'Query',
  getVideoProject: {
    __typename?: 'ProjectsResponseItem',
    id: string,
    name?: string | null,
    createdAt: string,
    createdBy: string,
    channelUCID: string,
    isPublic: boolean,
    publishDate?: string | null,
    status: ProjectStatusType,
    title?: string | null,
    thumbnail?: string | null,
    updatedAt: string,
    collaborators: Array<{
      __typename?: 'Collaborator',
      id?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      avatar?: string | null
    }>
  }
};

export type GetVideoProjectsQueryVariables = Exact<{
  input: GetVideoProjectsInput;
}>;


export type GetVideoProjectsQuery = {
  __typename?: 'Query',
  getVideoProjects: {
    __typename?: 'VideoProject',
    results: Array<{
      __typename?: 'ProjectsResponseItem',
      id: string,
      name?: string | null,
      createdAt: string,
      createdBy: string,
      channelUCID: string,
      isPublic: boolean,
      publishDate?: string | null,
      status: ProjectStatusType,
      title?: string | null,
      thumbnail?: string | null,
      updatedAt: string,
      collaborators: Array<{
        __typename?: 'Collaborator',
        id?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        avatar?: string | null
      }>
    }>
  }
};

export type DuplicateVideoMutationVariables = Exact<{
  input: VideoProjectInput;
}>;


export type DuplicateVideoMutation = {
  __typename?: 'Mutation',
  duplicateVideoProject?: {
    __typename?: 'ProjectsResponseItem',
    id: string,
    name?: string | null,
    createdAt: string,
    createdBy: string,
    channelUCID: string,
    isPublic: boolean,
    publishDate?: string | null,
    status: ProjectStatusType,
    title?: string | null,
    thumbnail?: string | null,
    updatedAt: string
  } | null
};

export type ChannelStatsListQueryVariables = Exact<{
  input: ChannelStatsListInput;
}>;


export type ChannelStatsListQuery = {
  __typename?: 'Query',
  channelStatsList: {
    __typename?: 'ChannelStatsListPagination',
    count?: number | null,
    currentPage?: number | null,
    currentPageSize?: number | null,
    numPages?: number | null,
    results: Array<{
      __typename?: 'ChannelStats',
      ucid: string,
      name?: string | null,
      thumbnail?: string | null,
      subscribers?: string | null,
      videoCount?: string | null,
      keywords?: string | null,
      description?: string | null,
      lifetimeViews?: string | null,
      runDate?: string | null
    }>
  }
};

export type ListTeamsQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type ListTeamsQuery = {
  __typename?: 'Query',
  team: {
    __typename?: 'Team',
    id: string,
    name?: string | null,
    channels: {
      __typename?: 'ChannelsWithMeta',
      edges: Array<{ __typename?: 'Channel', id: string, name: string, connectChannelId: string }>
    }
  }
};

export type CreateCheckoutSessionMutationVariables = Exact<{
  input: CheckoutInput;
}>;


export type CreateCheckoutSessionMutation = {
  __typename?: 'Mutation',
  createCheckoutSession: { __typename?: 'CheckoutSession', sessionId: string, clientSecret?: string | null }
};

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = {
  __typename?: 'Query', user: (
    { __typename?: 'User' }
    & { ' $fragmentRefs'?: { 'UserItemFragment': UserItemFragment } }
    )
};

export const ChannelItemFragmentDoc = {
  'kind': 'Document', 'definitions': [{
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'ChannelItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Channel' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'name' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'email' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'ucid' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'teams' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'isSpotterOrganization' } }],
            },
          }],
        },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'category' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'monetized' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'connectChannelId' } }],
    },
  }],
} as unknown as DocumentNode<ChannelItemFragment, unknown>;
export const TeamItemFragmentDoc = {
  'kind': 'Document', 'definitions': [{
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'TeamItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Team' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'name' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channels' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'slug' } }],
            },
          }],
        },
      }, {
        'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'subscriptions' }, 'selectionSet': {
          'kind': 'SelectionSet', 'selections': [{
            'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'edges' }, 'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'status' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'interval' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'startDate' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'resumesAt' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lastBillDate' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'chargeAmount' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'stripeSubscriptionId' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'failedBillingAttempts' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lastChargedAmount' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'freeTrial' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'startDate' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'endDate' } }],
                },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'plan' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': '_id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'name' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'cost' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'description' },
                  }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'price' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'annual' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'monthly' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'annualStripeId' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'monthlyStripeId' } }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'organizationUsers' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'organizationId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'user' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'email' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'firstName' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lastName' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'entitlements' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'channel' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'slug' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'ucid' },
                      }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<TeamItemFragment, unknown>;
export const UserItemFragmentDoc = {
  'kind': 'Document', 'definitions': [{
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'UserItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'User' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'firstName' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'email' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'jobTitle' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'phoneNumber' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'profilePicture' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'permissions' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'organizationUsers' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'status' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organization' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }],
            },
          }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'entitlements' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'source' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'createdAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'role' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'channel' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'slug' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'ucid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UserItemFragment, unknown>;
export const ChannelDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'Channel' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'channelId' } },
      'type': { 'kind': 'NonNullType', 'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } } },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'orgId' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channel' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'channelId' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'channelId' } },
        }, {
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'orgId' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'orgId' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'FragmentSpread', 'name': { 'kind': 'Name', 'value': 'ChannelItem' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'kirbyChannelMetadata' },
            'arguments': [{
              'kind': 'Argument',
              'name': { 'kind': 'Name', 'value': 'orgId' },
              'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'orgId' } },
            }],
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'bio' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'videoCount' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'tags' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'subscriberCount' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'videos' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'title' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'caption' } }],
                },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'socialLinks' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'isKidFriendly' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'manualLogLines' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'content' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'organizationId' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'updatedAt' },
                  }],
                },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'generatedLogLines' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'generatedVideoTitles' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'title' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'thumbnail' },
                  }],
                },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'creatorDescription' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'manualVideoTitles' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'title' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'organizationId' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'updatedAt' },
                  }],
                },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'manualBios' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'content' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'organizationId' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'updatedAt' },
                  }],
                },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'generatedLogLines' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'castMembers' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'name' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'description' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'manual' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'updatedAt' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'imageUrl' } }],
                },
              }],
            },
          }],
        },
      }],
    },
  }, {
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'ChannelItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Channel' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'name' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'email' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'ucid' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'teams' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'isSpotterOrganization' } }],
            },
          }],
        },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'category' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'monetized' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'connectChannelId' } }],
    },
  }],
} as unknown as DocumentNode<ChannelQuery, ChannelQueryVariables>;
export const ChannelsDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'Channels' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'page' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Int' } },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'pageSize' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Int' } },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'search' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'orgId' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'searchBy' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'ChannelSearchField' } },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'sort' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channels' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'page' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'page' } },
        }, {
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'orgId' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'orgId' } },
        }, {
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'pageSize' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'pageSize' } },
        }, {
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'search' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'search' } },
        }, {
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'searchBy' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'searchBy' } },
        }, {
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'sort' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'sort' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'page' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'nextPage' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'prevPage' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'pageSize' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'total' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'FragmentSpread', 'name': { 'kind': 'Name', 'value': 'ChannelItem' } }],
            },
          }],
        },
      }],
    },
  }, {
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'ChannelItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Channel' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'name' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'email' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'ucid' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'teams' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'isSpotterOrganization' } }],
            },
          }],
        },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'category' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'monetized' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'connectChannelId' } }],
    },
  }],
} as unknown as DocumentNode<ChannelsQuery, ChannelsQueryVariables>;
export const CreateVideoTitleDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'CreateVideoTitle' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CreateChannelVideoTitleInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createChannelVideoTitle' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'title' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<CreateVideoTitleMutation, CreateVideoTitleMutationVariables>;
export const UpdateChannelVideoTitleDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateChannelVideoTitle' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UpdateChannelVideoTitleInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateChannelVideoTitle' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'title' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateChannelVideoTitleMutation, UpdateChannelVideoTitleMutationVariables>;
export const DeleteChannelVideoTitleDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DeleteChannelVideoTitle' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'DeleteChannelVideoTitleInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteChannelVideoTitle' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
      }],
    },
  }],
} as unknown as DocumentNode<DeleteChannelVideoTitleMutation, DeleteChannelVideoTitleMutationVariables>;
export const CreateChannelBioDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'CreateChannelBio' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CreateChannelBioInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createChannelBio' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'content' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<CreateChannelBioMutation, CreateChannelBioMutationVariables>;
export const CreateChannelLogLineDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'CreateChannelLogLine' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CreateChannelLogLineInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createChannelLogLine' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'content' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<CreateChannelLogLineMutation, CreateChannelLogLineMutationVariables>;
export const UpdateChannelLoglineDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateChannelLogline' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UpdateChannelLoglineInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateChannelLogline' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'content' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateChannelLoglineMutation, UpdateChannelLoglineMutationVariables>;
export const DeleteChannelLogLineDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DeleteChannelLogLine' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'DeleteChannelLogLineInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteChannelLogLine' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
      }],
    },
  }],
} as unknown as DocumentNode<DeleteChannelLogLineMutation, DeleteChannelLogLineMutationVariables>;
export const UpdateChannelCastMemberDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateChannelCastMember' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UpdateChannelCastMemberInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateChannelCastMember' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'name' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'manual' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'imageUrl' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'description' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateChannelCastMemberMutation, UpdateChannelCastMemberMutationVariables>;
export const UpdateChannelBioDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateChannelBio' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UpdateChannelBioInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateChannelBio' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'content' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'updatedAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateChannelBioMutation, UpdateChannelBioMutationVariables>;
export const DeleteChannelBioDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DeleteChannelBio' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'DeleteChannelBioInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteChannelBio' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
      }],
    },
  }],
} as unknown as DocumentNode<DeleteChannelBioMutation, DeleteChannelBioMutationVariables>;
export const CreateSubscriptionDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'CreateSubscription' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CreateSubscriptionInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createSubscription' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
      }],
    },
  }],
} as unknown as DocumentNode<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'CancelSubscription' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CancelSubscriptionInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'cancelSubscription' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
      }],
    },
  }],
} as unknown as DocumentNode<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const GetTeamDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetTeam' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'OrganizationInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'team' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'FragmentSpread', 'name': { 'kind': 'Name', 'value': 'TeamItem' } }],
        },
      }],
    },
  }, {
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'TeamItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'Team' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'name' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channels' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'slug' } }],
            },
          }],
        },
      }, {
        'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'subscriptions' }, 'selectionSet': {
          'kind': 'SelectionSet', 'selections': [{
            'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'edges' }, 'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'status' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'interval' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'startDate' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'resumesAt' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lastBillDate' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'chargeAmount' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'stripeSubscriptionId' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'failedBillingAttempts' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lastChargedAmount' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'freeTrial' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'startDate' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'endDate' } }],
                },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'plan' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': '_id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'name' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'cost' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'description' },
                  }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'price' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'annual' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'monthly' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'annualStripeId' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'monthlyStripeId' } }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'organizationUsers' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'organizationId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'user' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'email' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'firstName' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lastName' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'entitlements' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'channel' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'slug' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'ucid' },
                      }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamsDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetTeams' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'search' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'teams' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'search' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'search' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channels' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'edges' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'name' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'slug' } }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetTeamsQuery, GetTeamsQueryVariables>;
export const GetUserTeamsDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetUserTeams' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'search' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'teams' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'search' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'search' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet', 'selections': [{
            'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'edges' }, 'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channels' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'edges' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'ucid' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
                      }],
                    },
                  }],
                },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'maxChannels' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'maxSeats' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'assignedSeats' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'assignedChannels' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'organizationUsers' }, 'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'role' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'status' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'organizationId' },
                  }, {
                    'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'user' }, 'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'firstName' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'email' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'permissions' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'profilePicture' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'jobTitle' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'phoneNumber' },
                      }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'entitlements' },
                        'selectionSet': {
                          'kind': 'SelectionSet',
                          'selections': [{
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'id' },
                          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'role' } }, {
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'source' },
                          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'channelId' },
                          }, {
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'organizationId' },
                          }, {
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'channel' },
                            'selectionSet': {
                              'kind': 'SelectionSet',
                              'selections': [{
                                'kind': 'Field',
                                'name': { 'kind': 'Name', 'value': 'id' },
                              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                                'kind': 'Field',
                                'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
                              }],
                            },
                          }],
                        },
                      }],
                    },
                  }],
                },
              }, {
                'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'subscriptions' }, 'selectionSet': {
                  'kind': 'SelectionSet', 'selections': [{
                    'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'edges' }, 'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'status' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'interval' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'startDate' },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'resumesAt' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'freeTrial' },
                        'selectionSet': {
                          'kind': 'SelectionSet',
                          'selections': [{
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'startDate' },
                          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'endDate' } }],
                        },
                      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastBillDate' } }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'chargeAmount' },
                      }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'stripeSubscriptionId' },
                      }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'failedBillingAttempts' },
                      }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'lastChargedAmount' },
                      }, {
                        'kind': 'Field',
                        'name': { 'kind': 'Name', 'value': 'plan' },
                        'selectionSet': {
                          'kind': 'SelectionSet',
                          'selections': [{
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': '_id' },
                          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'cost' },
                          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'description' } }, {
                            'kind': 'Field',
                            'name': { 'kind': 'Name', 'value': 'price' },
                            'selectionSet': {
                              'kind': 'SelectionSet',
                              'selections': [{
                                'kind': 'Field',
                                'name': { 'kind': 'Name', 'value': 'annual' },
                              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'monthly' } }],
                            },
                          }],
                        },
                      }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetUserTeamsQuery, GetUserTeamsQueryVariables>;
export const PlansDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'plans' },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'plans' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': '_id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'sku' } }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<PlansQuery, PlansQueryVariables>;
export const DeleteTeamUserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DeleteTeamUser' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'DeleteTeamUserInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteTeamUser' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<DeleteTeamUserMutation, DeleteTeamUserMutationVariables>;
export const DeleteTeamDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DeleteTeam' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'organizationId' } },
      'type': { 'kind': 'NonNullType', 'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } } },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteTeam' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'organizationId' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'organizationId' } },
        }],
      }],
    },
  }],
} as unknown as DocumentNode<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const UpdateTeamUserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateTeamUser' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UpdateTeamUserInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateTeamUser' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateTeamUserMutation, UpdateTeamUserMutationVariables>;
export const InviteUserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'InviteUser' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'InviteUserInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'inviteUser' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'firstName' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'jobTitle' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'phoneNumber' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<InviteUserMutation, InviteUserMutationVariables>;
export const UpdateEntitlementDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateEntitlement' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'EntitlementInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateEntitlement' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateEntitlementMutation, UpdateEntitlementMutationVariables>;
export const UpdateTeamDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateTeam' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'TeamInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateTeam' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const GetUserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetUser' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'userId' } },
      'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'String' } },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'user' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'id' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'userId' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'FragmentSpread', 'name': { 'kind': 'Name', 'value': 'UserItem' } }],
        },
      }],
    },
  }, {
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'UserItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'User' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'firstName' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'email' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'jobTitle' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'phoneNumber' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'profilePicture' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'permissions' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'organizationUsers' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'status' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organization' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }],
            },
          }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'entitlements' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'source' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'createdAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'role' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'channel' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'slug' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'ucid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetUsers' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UsersInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'users' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'page' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'pageSize' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'total' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'edges' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'FragmentSpread', 'name': { 'kind': 'Name', 'value': 'UserItem' } }],
            },
          }],
        },
      }],
    },
  }, {
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'UserItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'User' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'firstName' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'email' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'jobTitle' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'phoneNumber' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'profilePicture' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'permissions' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'organizationUsers' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'status' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organization' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }],
            },
          }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'entitlements' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'source' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'createdAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'role' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'channel' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'slug' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'ucid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const UpdateUserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateUser' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UserInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateUser' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'firstName' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'jobTitle' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'phoneNumber' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateOrgDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'UpdateOrg' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UserInput' } },
      },
    }, {
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'teamInput' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'TeamInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateUser' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'firstName' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'jobTitle' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'phoneNumber' } }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'updateTeam' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'teamInput' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UpdateOrgMutation, UpdateOrgMutationVariables>;
export const DeleteUserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DeleteUser' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'UserInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteUser' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetVideoInspirationDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetVideoInspiration' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'GetVideoInspirationInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'getVideoInspiration' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'thumbnail' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'title' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'videoId' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetVideoInspirationQuery, GetVideoInspirationQueryVariables>;
export const ChannelAutocompleteDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'ChannelAutocomplete' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'ChannelAutocompleteInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channelAutocomplete' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'count' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'videoTitle' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'page' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'total' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'prevPage' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'nextPage' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'pageSize' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'edges' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'title' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'videoId' },
                  }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'highlight' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'title1' } }],
                    },
                  }],
                },
              }],
            },
          }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'channelName' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'prevPage' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'nextPage' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'total' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'page' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'pageSize' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'edges' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'ucid' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'subscribers' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'thumbnail' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'score' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'highlight' },
                    'selectionSet': {
                      'kind': 'SelectionSet',
                      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }],
                    },
                  }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<ChannelAutocompleteQuery, ChannelAutocompleteQueryVariables>;
export const AddFavoriteDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'addFavorite' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CreateFavoriteInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'addFavorite' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'results' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'creatorChannelUcid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'favoriteUcid' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'organizationId' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'thumbnail' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'subscribers' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'videoCount' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'keywords' } }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<AddFavoriteMutation, AddFavoriteMutationVariables>;
export const DeleteFavoriteDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'deleteFavorite' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'DeleteFavoriteInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'deleteFavorite' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'status' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>;
export const FavoriteChannelsDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'favoriteChannels' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'ListFavoritesInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'favoriteChannels' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'results' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'creatorChannelUcid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'organizationId' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'favoriteUcid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'thumbnail' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'subscribers' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'status' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'videoCount' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'keywords' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'updatedAt' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<FavoriteChannelsQuery, FavoriteChannelsQueryVariables>;
export const GetVideosDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetVideos' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'ListVideosInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'videos' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'count' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'currentPage' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'currentPageSize' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'numPages' },
          }, {
            'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'results' }, 'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'category' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'clusterIndex' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'createdAt' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'duration' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'email' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'engagement' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'id' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'madeForKids' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'performanceIndex' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'predictedCategoryLevels1' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'predictedCategoryLevels2' },
              }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'predictedCategoryLevels3' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'predictedCategoryTags1' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'predictedCategoryTags2' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'predictedCategoryTags3' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'subscribers' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'thumb' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'title1' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'titleLanguage' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'updatedAt' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'uploaded' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'ucid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'v30Score' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'v7Average' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'v7Score' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'videoId' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'views' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'youtubeCategory' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetVideosQuery, GetVideosQueryVariables>;
export const GetVideoProjectDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetVideoProject' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'GetVideoProjectInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'getVideoProject' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'name' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'createdBy' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelUCID' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'isPublic' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'publishDate' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'status' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'title' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'thumbnail' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'collaborators' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'first_name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'last_name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'avatar' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetVideoProjectQuery, GetVideoProjectQueryVariables>;
export const GetVideoProjectsDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'GetVideoProjects' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'GetVideoProjectsInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'getVideoProjects' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet', 'selections': [{
            'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'results' }, 'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'createdBy' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelUCID' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'isPublic' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'publishDate' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'status' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'title' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'thumbnail' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'collaborators' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'first_name' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'last_name' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'avatar' },
                  }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<GetVideoProjectsQuery, GetVideoProjectsQueryVariables>;
export const DuplicateVideoDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'DuplicateVideo' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'VideoProjectInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'duplicateVideoProject' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'name' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'createdAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'createdBy' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelUCID' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'isPublic' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'publishDate' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'status' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'title' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'thumbnail' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<DuplicateVideoMutation, DuplicateVideoMutationVariables>;
export const ChannelStatsListDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'channelStatsList' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'ChannelStatsListInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet', 'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'channelStatsList' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'count' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'currentPage' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'currentPageSize' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'numPages' },
          }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'results' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'ucid' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'thumbnail' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'subscribers' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'videoCount' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'keywords' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'description' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'lifetimeViews' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'runDate' } }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<ChannelStatsListQuery, ChannelStatsListQueryVariables>;
export const ListTeamsDocument = {
  'kind': 'Document', 'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'ListTeams' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'OrganizationInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'team' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'name' },
          }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'channels' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'edges' },
                'selectionSet': {
                  'kind': 'SelectionSet',
                  'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                    'kind': 'Field',
                    'name': { 'kind': 'Name', 'value': 'name' },
                  }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'connectChannelId' } }],
                },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<ListTeamsQuery, ListTeamsQueryVariables>;
export const CreateCheckoutSessionDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'mutation',
    'name': { 'kind': 'Name', 'value': 'CreateCheckoutSession' },
    'variableDefinitions': [{
      'kind': 'VariableDefinition',
      'variable': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
      'type': {
        'kind': 'NonNullType',
        'type': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'CheckoutInput' } },
      },
    }],
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createCheckoutSession' },
        'arguments': [{
          'kind': 'Argument',
          'name': { 'kind': 'Name', 'value': 'input' },
          'value': { 'kind': 'Variable', 'name': { 'kind': 'Name', 'value': 'input' } },
        }],
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'sessionId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'clientSecret' },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const UserDocument = {
  'kind': 'Document',
  'definitions': [{
    'kind': 'OperationDefinition',
    'operation': 'query',
    'name': { 'kind': 'Name', 'value': 'user' },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'user' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'FragmentSpread', 'name': { 'kind': 'Name', 'value': 'UserItem' } }],
        },
      }],
    },
  }, {
    'kind': 'FragmentDefinition',
    'name': { 'kind': 'Name', 'value': 'UserItem' },
    'typeCondition': { 'kind': 'NamedType', 'name': { 'kind': 'Name', 'value': 'User' } },
    'selectionSet': {
      'kind': 'SelectionSet',
      'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'firstName' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'lastName' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'email' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'jobTitle' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'phoneNumber' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'profilePicture' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'createdAt' },
      }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'permissions' },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'organizationUsers' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'role' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'status' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organization' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'name' },
              }],
            },
          }],
        },
      }, {
        'kind': 'Field',
        'name': { 'kind': 'Name', 'value': 'entitlements' },
        'selectionSet': {
          'kind': 'SelectionSet',
          'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'organizationId' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'userId' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'source' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'updatedAt' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'createdAt' },
          }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'role' } }, {
            'kind': 'Field',
            'name': { 'kind': 'Name', 'value': 'channel' },
            'selectionSet': {
              'kind': 'SelectionSet',
              'selections': [{ 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'id' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'slug' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'name' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'ucid' },
              }, { 'kind': 'Field', 'name': { 'kind': 'Name', 'value': 'channelBanner' } }, {
                'kind': 'Field',
                'name': { 'kind': 'Name', 'value': 'channelThumbnail' },
              }],
            },
          }],
        },
      }],
    },
  }],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
