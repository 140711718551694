import { forwardRef, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tiptap/react';
import { Button, Icons, Select } from '@lib/ui';
import * as styles from './toolbar.css';

const toolbarHeadings = [
  { value: 'p', icon: null, title: 'Paragraph' },
  { value: 'h1', icon: <Icons.Header1Icon aria-hidden />, title: 'Heading 1' },
  { value: 'h2', icon: <Icons.Header2Icon aria-hidden />, title: 'Heading 2' },
  { value: 'h3', icon: <Icons.Header3Icon aria-hidden />, title: 'Heading 3' },
];

const SelectItem = ({
  children,
  ...props
}: {
  children: ReactNode;
  value: string;
}) => {
  return (
    <Select.Item {...props}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
    </Select.Item>
  );
};

type Props = {
  editor: Editor;
};

export const ToolbarHeadings = forwardRef<HTMLDivElement, Props>(
  ({ editor }, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = useCallback(
      (value: string) => {
        if (!editor) {
          return;
        }
        switch (value) {
          case 'p':
            editor.chain().focus().setParagraph().run();
            break;
          case 'h1':
            editor.chain().focus().setHeading({ level: 1 }).run();
            break;
          case 'h2':
            editor.chain().focus().setHeading({ level: 2 }).run();
            break;
          case 'h3':
            editor.chain().focus().setHeading({ level: 3 }).run();
            break;
        }
        setIsOpen(false);
      },
      [editor]
    );

    return (
      <Select.Root
        value={getCurrentHeading(editor)}
        onValueChange={handleChange}
      >
        <Select.Trigger asChild>
          <Button
            fill="none"
            variant="light"
            size="xs"
            className={styles.headingsTrigger}
            adornmentEnd={
              <Icons.ChevronDownIcon aria-label={t('select heading')} />
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {getCurrentHeadingTitle(editor)}
          </Button>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content>
            <Select.Viewport>
              <Select.Group>
                {toolbarHeadings.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.icon} {t(item.title)}
                  </SelectItem>
                ))}
              </Select.Group>
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    );
  }
);

function getCurrentHeading(editor: Editor) {
  if (editor.isActive('heading', { level: 1 })) {
    return 'h1';
  }
  if (editor.isActive('heading', { level: 2 })) {
    return 'h2';
  }
  if (editor.isActive('heading', { level: 3 })) {
    return 'h3';
  }
  return 'p';
}

function getCurrentHeadingTitle(editor: Editor) {
  const heading = getCurrentHeading(editor);
  const headingItem = toolbarHeadings.find((item) => item.value === heading);
  return headingItem ? headingItem.title : 'Paragraph';
}
